#nprogress .bar {
  background: #20c163 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #20c163, 0 0 5px #20c163;
}

#nprogress .spinner-icon {
  border-top-color: #20c163;
  border-left-color: #20c163;
}
